import styled from "styled-components/macro"
import { Box, Heading, Paragraph } from "~styled"

const Card = styled(Box).attrs({ className: "related-card" })`
  position: relative;
  margin-bottom: 25px;
  border-radius: 8px;
  display: inline-block;
  margin-bottom: 25px;
  .card-innerblock {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 100%;
    transform: translateX(-50%);
    overflow: hidden;
    visibility: visible;
    opacity: 1;
  }
  &:hover {
    .card-innerblock {
      visibility: visible;
      opacity: 1;
    }
  }
`

Card.InnerBlock = styled(Box).attrs({ className: "card-innerblock" })`
  padding: 8px 16px;
  border-radius: 0;
  transition: all 0.7s ease-in-out;
  flex-direction: column;
  @media (min-width: 480px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .card-icon {
    @media (min-width: 480px) {
      order: 2;
    }
  }
  .card-content {
    @media (min-width: 480px) {
      order: 1;
    }
  }
`

Card.Title = styled(Heading)`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  margin-bottom: 0;

  @media (min-width: 35.9375rem) {
    font-size: 21px;
  }
`

Card.Text = styled(Paragraph)`
  font-size: 15px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.2;
  margin-bottom: 0;
`

Card.InnerIcon = styled(Paragraph)`
  font-weight: 500;
  font-size: 21px;
  line-height: 30px;
  @media (min-width: 480px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  i {
    margin: 0 10px;
    font-size: 18px;
    color: inherit;
  }
`

Card.Content = styled(Box).attrs({ className: "card-content" })``

Card.SocialItem = styled(Box)`
  color: #fff;
  padding-right: 20px;
  transition-duration: 0.4s;
`
Card.Box = styled(Box)``

Card.Image = styled(Box)`
  width: 100%;
  min-height: 320px;
  border-radius: 8px;
  background-size: cover;
  position: relative;
  left: 0;
  bottom: 0px;
  padding: 30px;
  background-image: ${(props) =>
    `linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0)   50%, rgba(0, 0, 0, 0) 100%), url(${props.backgroundImage})`};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: 1s;
  color: #fff;
`

Card.OverlayBlock = styled(Box)`
  width: 100%;
  border-radius: 8px;
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: 0px;
  padding: 30px;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: 1s;
  color: #fff;
  &:hover {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.4) 100%);
  }
`

export default Card
